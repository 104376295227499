import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';

import { CartContext } from './CartProvider';
import { fmtPrice } from '../utils';
import AnimatedButtonWrapper from './utils/AnimatedButtonWrapper';

const stripePromise = loadStripe('pk_live_f1HmQc2AQtuiKU4ZXUQ6A1AZ00Ib1mPpNt');

const Checkout = ({ subtotal, shipping }) => {
  const { cartInfo, currency } = useContext(CartContext);

  const lineItems = [];

  for (const productId in cartInfo) {
    if ({}.hasOwnProperty.call(cartInfo, productId)) {
      const { item, quantity } = cartInfo[productId];
      lineItems.push({ price: item[currency].id, quantity });
    }
  }

  // add shipping to cart if total less than 120 cad or 95usd
  if ((currency === 'cad' && subtotal < 12000) || (currency === 'usd' && subtotal < 9500)) {
    lineItems.push({ price: shipping[currency].id, quantity: 1 });
  }

  const redirectToCheckout = async () => {
    if (typeof window !== 'undefined') {
      const url = window.location.origin;
      document.cookie = 'checkout=yes';
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        mode: 'payment',
        lineItems,
        billingAddressCollection: 'required',
        shippingAddressCollection: { allowedCountries: ['CA'] },
        successUrl: `${url}/order-confirmation`,
        cancelUrl: `${url}/cart`
      });

      if (error) {
        console.error(error.message);
      }
    }
  };

  return (
    <article className="tile is-child">
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item has-text-centered">
            <AnimatedButtonWrapper
              className="button is-black"
              onClick={redirectToCheckout}
              type="button"
              aria-label="Checkout"
            >
              Checkout
            </AnimatedButtonWrapper>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item has-text-centered">
            <div>
              <span className="title is-4">Total: </span>
              <span className="subtitle is-4 is-italic">{fmtPrice(currency, subtotal)}</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

Checkout.defaultProps = {
  shipping: null
};

Checkout.propTypes = {
  subtotal: PropTypes.number.isRequired,
  shipping: PropTypes.object
};

export default Checkout;
