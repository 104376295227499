import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import { CartContext } from '../../CartProvider';
import { fmtPrice, getCookie, getExpiraton } from '../../../utils';
import { imgWidth, imgFormatting, columnHeight, inputStyling, deletion } from './CartCard.module.scss';

const CartCard = ({ productId, readOnly }) => {
  // --- State --- //

  const context = useContext(CartContext);

  const { currency } = context;

  let cartInfo;
  let setCartInfo;
  if (readOnly) {
    cartInfo = JSON.parse(getCookie('orderInfo'));
  } else {
    ({ cartInfo, setCartInfo } = context);
  }

  const { item, title, image, option, quantity, link } = cartInfo[productId];

  const [qty, setQty] = useState(quantity);

  // --- Methods --- //

  /**
   * Removes product from cart.
   */
  const removeItem = () => {
    // remove cart item from the Context
    const { [productId]: removed, ...newCartInfo } = cartInfo;
    setCartInfo(newCartInfo);
    // remove cart item from local storage to reflect Contexy
    const expires = getExpiraton();
    localStorage.setItem('cartInfo', JSON.stringify({ cartInfo: newCartInfo, expires }));
  };

  /**
   * Updates the cart info.
   * @param {number} val - new quantity value
   */
  const updateProductId = (val) => {
    // update Context
    const newCartInfo = { ...cartInfo, [productId]: { item, quantity: val, title, option, image, link } };
    setCartInfo(newCartInfo);
    // update local storage
    const expires = getExpiraton();
    localStorage.setItem('cartInfo', JSON.stringify({ cartInfo: newCartInfo, expires }));
  };

  /**
   * Increments the product quantity.
   */
  const incrementQuantity = () => {
    const newQty = qty + 1;
    setQty(newQty);
    updateProductId(newQty);
  };

  /**
   * Decrements the product quantity.
   */
  const decrementQuantity = () => {
    const newQty = qty - 1;
    setQty(newQty);
    updateProductId(newQty);
  };

  return (
    <motion.article
      key={productId}
      initial={{ x: '-100%', opacity: 0 }}
      animate={{ x: 0, opacity: 1, transition: { duration: 0.5 } }}
      exit={{ scale: 0.65, opacity: 0, transition: { duration: 0.5 } }}
      className="tile is-child box columns is-relative"
    >
      <div className="column">
        <div className="columns is-centered is-vcentered is-mobile">
          <div className="column is-narrow">
            <div className={imgWidth}>
              <figure className="image is-square">
                <img className={`${imgFormatting} has-background-white-bis`} src={image} alt="" />
              </figure>
            </div>
          </div>
          <div className="column">
            <Link className="title is-4" to={link}>
              {option ? `${title} - ${option}` : title}
            </Link>
          </div>
        </div>
      </div>
      <hr className="is-hidden-tablet" />
      <div className="column">
        <div className="columns is-vcentered is-mobile">
          <div className="column">
            <div className={columnHeight}>
              {readOnly ? (
                <p className="subtitle has-text-centered">{`Qty: ${qty}`}</p>
              ) : (
                <div className="field has-addons has-addons-centered">
                  <p className="control">
                    <button
                      className="button"
                      type="button"
                      onClick={decrementQuantity}
                      disabled={qty === 1}
                      aria-label="Decrease quantity"
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={faMinus} />
                      </span>
                    </button>
                  </p>
                  <p className="control">
                    <input
                      className={`input has-text-centered ${inputStyling}`}
                      type="text"
                      value={qty}
                      readOnly
                      aria-label="Item quantity"
                    />
                  </p>
                  <p className="control">
                    <button
                      className="button"
                      type="button"
                      onClick={incrementQuantity}
                      disabled={qty === 10}
                      aria-label="Increase quantity"
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                    </button>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="column">
            <p className="subtitle is-4 has-text-centered is-italic">
              {fmtPrice(currency, item[currency].price * qty)}
            </p>
          </div>
        </div>
      </div>
      {readOnly || (
        <div className={deletion}>
          <button className="delete" type="button" aria-label="Delete item" onClick={removeItem} />
        </div>
      )}
    </motion.article>
  );
};

CartCard.defaultProps = {
  readOnly: false
};

CartCard.propTypes = {
  productId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

export default CartCard;
