import React from 'react';
import PropTypes from 'prop-types';

import { fmtPrice } from '../../../utils';

const ShippingCard = ({ currency, price, showShipping }) => (
  <>
    {showShipping && (
      <article className="tile is-child box columns is-relative">
        <div className="column">
          <div className="columns is-centered is-vcentered is-mobile">
            <div className="column">
              <p className="title is-4">Canada-wide Shipping</p>
            </div>
          </div>
        </div>
        <hr className="is-hidden-tablet" />
        <div className="column">
          <div className="columns is-vcentered is-mobile">
            <div className="column" />
            <div className="column">
              <p className="subtitle is-4 has-text-centered is-italic">{fmtPrice(currency, price)}</p>
            </div>
          </div>
        </div>
      </article>
    )}
  </>
);

ShippingCard.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  showShipping: PropTypes.bool.isRequired
};

export default ShippingCard;
