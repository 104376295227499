import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { CartContext } from '../../components/CartProvider';
import Wrapper from '../../components/Wrapper/Wrapper';
import emptyCartSvg from '../../assets/svg/empty-cart.svg';
import Grid from '../../components/Grid/Grid';
import CartCard from '../../components/Cards/Cart/CartCard';
import Checkout from '../../components/Checkout';
import { img, alignment } from './index.module.scss';
import ShippingCard from '../../components/Cards/Cart/ShippingCard';

const EmptyCart = () => (
  <article className={`tile is-child ${alignment}`}>
    <h2 className="title has-text-centered">Looks like your cart is empty!</h2>
    <img className={img} src={emptyCartSvg} alt="Illustration of a man next to an empty shopping cart." />
  </article>
);

const Cart = ({ data }) => {
  const { cartInfo, currency } = useContext(CartContext);
  const [subtotal, setSubtotal] = useState(0);

  let cards = [];
  const cartItems = Object.keys(cartInfo);

  const shipping = {};
  for (const { node } of data.allStripePrice.edges) {
    shipping[node.currency] = { id: node.id, price: node.unit_amount };
  }

  // hardcoded
  const shippingCutOff = {
    cad: 12000,
    usd: 9500
  };

  const [shippingRequired, setShippingRequired] = useState(false);

  useEffect(() => {
    if (cartItems.length > 0) {
      let sum = 0;
      for (const productId in cartInfo) {
        if ({}.hasOwnProperty.call(cartInfo, productId)) {
          const { item, quantity } = cartInfo[productId];
          sum += item[currency].price * quantity;
        }
      }

      if (sum < shippingCutOff[currency]) {
        sum += shipping[currency].price;
        setShippingRequired(true);
      } else {
        setShippingRequired(false);
      }

      setSubtotal(sum);
    }
  }, [cartInfo, currency, subtotal]);

  if (cartItems.length > 0) {
    cards = cartItems.map((productId) => <CartCard key={productId} productId={productId} />);
    cards.push(
      <ShippingCard
        key="shipping"
        currency={currency}
        price={shipping[currency].price}
        showShipping={shippingRequired}
      />
    );
    cards.push(<Checkout key="stripe" subtotal={subtotal} shipping={shipping} />);
  } else {
    cards.push(<EmptyCart key="empty" className="undraw-svg" />);
  }

  return (
    <Wrapper>
      <main id="container" className="container">
        <h1 className="title is-1 has-text-centered">Cart</h1>
        <Grid cols={1}>{cards}</Grid>
      </main>
    </Wrapper>
  );
};

Cart.propTypes = {
  data: PropTypes.object.isRequired
};

export default Cart;

export const query = graphql`
  query ShippingQuery {
    allStripePrice(filter: { active: { eq: true }, product: { id: { eq: "prod_K5dzpQHJaUn9i8" } } }) {
      edges {
        node {
          unit_amount
          currency
          id
        }
      }
    }
  }
`;
